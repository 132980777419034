import axios from 'axios';

import API  from './index.js'

export const sysNewsList = (param) => {     //资讯列表
    return axios.request({
        method: "post",
        url: API.SYS_API + '/sysNews/list',
        data: param
    })
};
export const sysCertList = (param) => {     //证书列表
    return axios.request({
        method: "post",
        url: API.CORE_API + '/sysCert/list',
        data: param
    })
};
export const sysNewsGetById = (param) => {     //资讯列表
    return axios.request({
        method: "get",
        url: API.SYS_API + '/sysNews/getById/'+param,
    })
};